import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Step1.module.scss';
import Header from 'components/Header';
import { useForm } from 'react-hook-form';
import FormInput from 'components/FormInput';
import Button from 'components/Button';
import Footer from 'components/Footer';
import { getStepPage_1, sendCarParams } from 'api/step_1';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActualStep,
  setSuccessfulStep_1,
  setSuccessfulStep_2,
  setSuccessfulStep_3,
  setSuccessfulStep_4,
} from 'store/formStepSlice';
import { firstStepData, setFirstStepData } from 'store/formDataSlice';
import { setErrorData } from 'store/errorsSlice';
import { urlParams } from 'store/urlParamsSlice';
import { setStep2Response } from 'store/step2PageDataSlice';
import { getErrorText } from 'utils/getErrorText';
import { step1PageResponse, setStep1Response } from 'store/step1PageDataSlice';
import Spinner from 'components/Spinner/Spinner';

const cx = classNames.bind(styles);

const Step1 = () => {
  const dispatch = useDispatch();
  const dafaultFormData = useSelector(firstStepData);
  const urlParamsData = useSelector(urlParams);

  const pageData = useSelector(step1PageResponse);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);

  const getStepPageData = () => {
    setIsLoading(true);
    getStepPage_1(urlParamsData)
      .then((res) => {
        dispatch(setStep1Response(res.data.data));
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch(setErrorData(error.response));
        dispatch(setActualStep('errorPage'));
        setIsLoading(false);
      });
  };

  const onSubmit = () => {
    setIsLoading(true);

    const sendData = getValues();

    dispatch(setFirstStepData(sendData));

    sendCarParams(urlParamsData, sendData)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setSuccessfulStep_1(true));
          dispatch(setActualStep(2));
          dispatch(setStep2Response(res.data.data));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          error?.response?.data?.errors.forEach(({ key, message }) =>
            setError(key, { type: 'focus', message: message[0] }),
          );
          setIsLoading(false);
        } else {
          dispatch(setErrorData(error.response));
          dispatch(setActualStep('errorPage'));
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    !pageData && getStepPageData();
    setDefaultFormValues();
  }, []);

  const setDefaultFormValues = () => {
    reset({
      vin: dafaultFormData.vin,
      mileage: dafaultFormData.mileage,
    });
  };

  const comparisonField = (value, name) => {
    if (value !== dafaultFormData[name]) {
      dispatch(setSuccessfulStep_1(false));
      dispatch(setSuccessfulStep_2(false));
      dispatch(setSuccessfulStep_3(false));
      dispatch(setSuccessfulStep_4(false));
    }
  };

  const renderFormFields = (field) => {
    switch (field.type) {
      case 'vin':
        return (
          <FormInput
            name={field.name}
            type="text"
            label={field.placeholder}
            maxLength={17}
            handleChange={(value, name) => comparisonField(value, name)}
            tooltip={field.tooltip ? field.tooltip : null}
            control={control}
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
              validate: {
                // vinFormat: (value) =>
                //   /\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/gm.test(value) ||
                //   getErrorText(field.validateTextError, 'regex'),
                // vinFormat1: (value) =>
                //   /^[A-HJ-NPR-Za-hj-npr-z\d]{8}[\dX][A-HJ-NPR-Za-hj-nnpr-z\d]{2}\d{6}$/gi.test(
                //     value,
                //   ) || getErrorText(field.validateTextError, 'regex'),
                vinFormat3: (value) =>
                /^([0-9A-HJ-NPR-Z]{9})([A-HJ-NPR-TV-Y0-9])([0-9A-HJ-NPR-Z])([0-9A-HJ-NPR-Z]{2}\d{4})$/i.test(
                    value,
                  ) || getErrorText(field.validateTextError, 'regex'),
                space: (value) =>
                  !/^\s*$/.test(value) ||
                  getErrorText(field.validateTextError, 'required'),
              },
            }}
            errors={errors[field.name]}
          />
        );
      case 'text':
        return (
          <FormInput
            name={field.name}
            type="text"
            label={field.placeholder}
            handleChange={(value, name) => comparisonField(value, name)}
            tooltip={field.tooltip ? field.tooltip : null}
            control={control}
            rules={{
              required: getErrorText(field.validateTextError, 'required'),
              validate: {
                integer: (value) =>
                  /^(0|[1-9][\d]{0,5})$/.test(value) ||
                  getErrorText(field.validateTextError, 'integer'),
                space: (value) =>
                  !/^\s*$/.test(value) ||
                  getErrorText(field.validateTextError, 'required'),
              },
            }}
            errors={errors[field.name]}
          />
        );
      default:
        return '';
    }
  };

  return (
    <div className={cx('Step1')}>
      <Header title={pageData?.title || ''} stepNumber="1" />
      {!isLoading ? (
        <>
          {pageData?.warranty?.list || pageData?.warranty?.title ? (
            <div className={cx('additionalInfo')}>
              <div className="container">
                <div className="row">
                  <div className={cx('additionalInfo__title')}>
                    <h2>{pageData?.warranty.title}</h2>
                  </div>

                  <div
                    className={cx('additionalInfo__list')}
                    dangerouslySetInnerHTML={{
                      __html: pageData?.warranty.list || '',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="container">
            <div className="row">
              <div className={cx('form-wrap', 'firstStepForm')}>
                <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
                  {pageData?.form.fields.map((el, i) => (
                    <div className={cx('form__line')} key={i}>
                      {renderFormFields(el)}
                    </div>
                  ))}
                  <div className={cx('form__line')}>
                    <Button
                      btnText={pageData?.form.submitBtnText}
                      disabled={isLoading}
                    />
                  </div>
                </form>
                <Footer>
                  <div
                    className={cx('footer')}
                    dangerouslySetInnerHTML={{
                      __html: pageData?.disclaimer || '',
                    }}
                  />
                </Footer>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Step1;
