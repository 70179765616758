import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from 'assets/svg/SearchIcon';
import classNames from 'classnames/bind';
import styles from './searchInput.module.scss';
import CurrentLocationIcon from 'assets/svg/CurrentLocationIcon';
import DeleteIcon from 'assets/svg/DeleteIcon';
import Button from 'components/Button';
import { getCityMapBox } from 'api/step_3';
import {
  selectedDealer,
  setDealersResponse,
  setSelectedDealer,
  setShowDealerList,
  setShowError,
  setStaticDealer,
  showDealerListStatus,
} from 'store/step3PageDataSlice';
import {
  setActualStep,
  setSuccessfulStep_3,
  setSuccessfulStep_4,
} from 'store/formStepSlice';
import ShowMoreContact from 'components/MapComponent/components/ShowMoreContact';
import TooltipIcon from 'assets/svg/TooltipIcon';
import { showError } from 'store/step3PageDataSlice';
import { setStep4Response } from 'store/step4PageDataSlice';
const cx = classNames.bind(styles);

const SearchInput = ({
  onChangeFc,
  searchResult,
  placeholder = '',
  getSelectedValue,
  showDealerListHendler,
  dealerList,
  errorText = 'Not found',
}) => {
  const dispatch = useDispatch();
  const dealer = useSelector(selectedDealer);
  const [value, setValue] = useState('');
  const [showResultState, setShowResultState] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [clickedDealerItem, setClickedDealerItem] = useState(null);

  const showDealerList = useSelector(showDealerListStatus);
  const error = useSelector(showError);

  const onCloseResult = () => {
    setShowResultState(false);
    dispatch(setShowDealerList(false));
  };

  const onShowResult = () => {
    if (searchResult.length > 0) {
      setShowResultState(true);
    } else {
      setShowResultState(false);
    }
  };

  const onSelectDealer = (dealerCode) => {
    dispatch(setSelectedDealer(dealerCode));
    dispatch(setSuccessfulStep_3(true));
    dispatch(setSuccessfulStep_4(false));
    dispatch(setStaticDealer(null));
    dispatch(setStep4Response({}));
    dispatch(setActualStep(4));
  };

  useEffect(() => {
    searchResult.length && onShowResult();
  }, [searchResult]);

  useEffect(() => {
    error && setShowResultState(false);
  }, [error]);

  useEffect(() => {
    showDealerListHendler(showDealerList);
  }, [showDealerList]);

  const renderDealers = () => {
    return (
      <ul className={cx('dealerList')}>
        {dealerList &&
          dealerList.map((el, index) => (
            <li className={cx('dealerList__item')} key={`index-${index + 0}`}>
              <div className={cx('dealerList__item-row', 'dealer')}>
                <span
                  className={cx(
                    'dealer__number',
                    clickedDealerItem === index ? 'active' : '',
                  )}
                >
                  {index + 1}
                </span>
                <span className={cx('dealer__distance')}>{el.distance}</span>
              </div>
              <div className={cx('dealerList__item-row')}>
                <span className={cx('dealer__name')}>{el.name}</span>
              </div>
              <div className={cx('dealerList__item-row', 'btnContainer')}>
                <Button
                  btnText={el.btnChooseDealerText}
                  onClick={() => onSelectDealer(el?.dealerCode)}
                />
              </div>
              <div className={cx('dealerList__item-row')}>
                <ShowMoreContact
                  btnTextShow={el.moreInformationShowText}
                  btnTextHide={el.moreInformationHideText}
                  onClickedItem={(itemNumber) =>
                    setClickedDealerItem(itemNumber)
                  }
                  indexItem={index}
                >
                  <div className={cx('dealerList__contacts')}>
                    {el.fields.map((item, index) => (
                      <div
                        className={cx('dealerList__contacts-line')}
                        key={`index-${index}`}
                      >
                        <span>{item.label}</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.value || '',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </ShowMoreContact>
              </div>
            </li>
          ))}
      </ul>
    );
  };
  useEffect(() => {
    if (!value.length) {
      dispatch(setShowDealerList(false));
      setShowResultState(false);
      dispatch(setShowError(false));
    }
  }, [value]);

  return (
    <div className={cx('searchInput')}>
      <span className={cx('icon')}>
        <SearchIcon />
      </span>
      <input
        className={cx('searchInput__input')}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChangeFc(e.target.value);
          // handleSearchCity(e.target.value);
        }}
      />
      {value.length > 0 && (
        <div className={cx('delete-icon-wrap')}>
          <DeleteIcon
            className={cx('delete-icon')}
            onClick={() => {
              setValue('');
              onCloseResult();
            }}
          />
        </div>
      )}
      {error && (
        <span className={cx('error')}>
          <span className={cx('error__text')}>{errorText}</span>
        </span>
      )}

      {showResultState && (
        <ul
          className={cx('searchInput__result', showResultState ? 'show' : '')}
        >
          {searchResult.map((el, index) => (
            <li
              className={cx(
                'searchInput__result-item',
                activeIndex === index ? 'active' : '',
              )}
              onClick={() => {
                setValue(`${el.name}`);
                setActiveIndex(index);
                onCloseResult();
                getSelectedValue({
                  name: el.name,
                  latitude: el.latitude,
                  longitude: el.longitude,
                });
                // setShowDealersList(true);
                dispatch(setShowDealerList(true));
              }}
              key={index}
            >
              <CurrentLocationIcon
                className={cx('searchInput__result-item-icon')}
              />
              <span>{el?.name}</span>
              <span>{el?.country}</span>
            </li>
          ))}
        </ul>
      )}

      {showDealerList && renderDealers()}
    </div>
  );
};

export default SearchInput;
