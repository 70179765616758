import * as React from 'react';

const ArrowCheck = (props) => (
  <svg
    width={10}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 7"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.58 1.586 1.64.525 5 3.885 8.36.524l1.06 1.06L5 6.006.58 1.586Z"
      fill={props?.color ? props.color : '#282830'}
    />
  </svg>
);

export default ArrowCheck;
