import React, { useState, useMemo, useEffect } from 'react';

import classNames from 'classnames/bind';
import styles from './MapNavigation.module.scss';
import RatioComponent from 'components/RatioComponent';
import LocationIcon from 'assets/svg/LocationIcon';
import SearchInput from 'components/SearchInput';
import { getCityMapBox, searchDealerRequest } from 'api/step_3';
import { useDispatch, useSelector } from 'react-redux';
import { urlParams } from 'store/urlParamsSlice';
import debounce from 'lodash/debounce';
import { FlyToInterpolator } from '@deck.gl/core';
import {
  dealerResponse,
  setDealersResponse,
  step3PageResponse,
  setShowDealerList,
  showDealerListStatus,
  setShowError,
} from 'store/step3PageDataSlice';
import { getLang } from 'utils/getLang';
const cx = classNames.bind(styles);

const MapNavigation = ({ fetchLocation, mapBoxToken, onViewportChange }) => {
  const dispatch = useDispatch();
  const urlParamsData = useSelector(urlParams);
  const navigatePanelData = useSelector(step3PageResponse);


  const getCurrentLocation = () => {

    navigator.geolocation.getCurrentPosition((position) => {
      setSelectedCity({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    });
  };

  const [searchBy, setSearchBy] = useState('city');
  const [searchInputPlaceholder, setSearchInputPlaceholder] = useState(
    navigatePanelData?.form?.placeholderCity || '',
  );
  const [searchResult, setSearchResult] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const dealerList = useSelector(dealerResponse);
  const showDealerList = useSelector(showDealerListStatus);

  const lang = getLang(urlParamsData.lang).lang;

  const onChangeSearchValue = (value) => {
    let params = {
      ...urlParamsData,
      searchText: value,
      searchBy: searchBy,
    };

    if (value.length > 2 && searchBy === 'city') {
      getCityMapBox(value, mapBoxToken, lang).then((res) => {
        if (!res.error) {
          const filteredResults = res.data.features.filter(
            (feature) =>
              feature.place_type.includes('place') &&
              feature.properties.wikidata &&
              feature.properties.wikidata.length > 0,
          );

          let result = filteredResults.map((el) => ({
            name: el.text,
            latitude: el.center[1],
            longitude: el.center[0],
            country: el.place_name.split(' ').pop(),
          }));

          if (result.length === 0) {
            dispatch(setShowError(true));
            dispatch(setShowDealerList(false));
            dispatch(setDealersResponse(null));
          } else {
            dispatch(setDealersResponse(null));
            dispatch(setShowError(false));
            dispatch(setShowDealerList(false));
          }

          setSearchResult(result);
        }
      });
    }
    if (value.length > 2 && searchBy === 'dealer') {
      searchDealerRequest({
        lang: params.lang,
        mark: params.mark,
        distance: 2000,
        latitude: 59.804904,
        longitude: 30.320802,
      })
        .then((res) => {
          if (res) {
            const filteredItems = res.data.data.filter((item) =>
              item.name.toLowerCase().includes(value.toLowerCase()),
            );

            dispatch(setDealersResponse(filteredItems));

            if (filteredItems.length === 0) {
              dispatch(setShowError(true));
              dispatch(setShowDealerList(false));
              dispatch(setDealersResponse(null));
            } else {
              dispatch(setDealersResponse(filteredItems));
              dispatch(setShowError(false));
              dispatch(setShowDealerList(true));
            }
          }
          // setIsLoading(false);
        })
        .catch((error) => {
          // dispatch(setErrorData(error.response));
          // dispatch(setActualStep('errorPage'));
          // setIsLoading(false);
        });
    }
  };

  const debounceOnChangeSearchValue = useMemo(
    () => debounce(onChangeSearchValue, 600),
    [searchBy],
  );

  useEffect(() => {
    selectedCity &&
      onViewportChange({
        zoom: 10,
        latitude: selectedCity.latitude,
        longitude: selectedCity.longitude,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
      });
    selectedCity &&
      searchDealerRequest({
        latitude: selectedCity.latitude,
        longitude: selectedCity.longitude,
        distance: 200,
        mark: urlParamsData.mark,
        lang: urlParamsData.lang,
      })
        .then((res) => {
          if (res && res.data.data.length > 0) {
            dispatch(setDealersResponse(res.data.data));
            dispatch(setShowDealerList(true));
          } else {
            dispatch(setShowError(true));
          }
        })
        .catch((error) => {
          console.error(error);
        });
  }, [selectedCity]);

  return (
    <div className={cx('mapNavigation', showDealerList && 'showResult')}>
      <div className={cx('mapNavigation__row', 'p0', 'pb9')}>
        <SearchInput
          onChangeFc={debounceOnChangeSearchValue}
          searchResult={searchResult}
          placeholder={searchInputPlaceholder}
          getSelectedValue={(city) => setSelectedCity(city)}
          showDealerListHendler={(show) => dispatch(setShowDealerList(show))}
          mapBoxToken={mapBoxToken}
          dealerList={dealerList}
          errorText={navigatePanelData.notFound}
        />
      </div>
      <div className={cx('mapNavigation__row')}>
        <RatioComponent
          onChangeFc={(value) => {
            setSearchBy(value);
            if (value === 'city') {
              setSearchInputPlaceholder(
                navigatePanelData?.form?.placeholderCity,
              );
            } else if (value === 'dealer') {
              setSearchInputPlaceholder(
                navigatePanelData?.form?.placeholderDealer,
              );
            }
          }}
          opt={navigatePanelData?.form?.fields}
          defaultChecked={searchBy}
        />
      </div>
      <div className={cx('mapNavigation__row')}>
        <div className={cx('locationBtn')} onClick={() => getCurrentLocation()}>
          <span className={cx('locationBtn__icon')}>
            <LocationIcon />
          </span>
          {navigatePanelData?.form?.useCurrentLocationPlaceholder}
        </div>
      </div>
    </div>
  );
};

export default MapNavigation;
