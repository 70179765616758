import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Step3.module.scss';
import Header from 'components/Header';
import MapComponent from 'components/MapComponent';
import { useDispatch, useSelector } from 'react-redux';
import { urlParams } from 'store/urlParamsSlice';
import Button from 'components/Button';
import {
  setActualStep,
  setSuccessfulStep_3,
  setSuccessfulStep_4,
} from 'store/formStepSlice';
import { getStepPage_3, searchDealerRequest } from 'api/step_3';
import { setErrorData } from 'store/errorsSlice';
import {
  dealerResponse,
  setStaticDealer,
  setStep3Response,
  step3PageResponse,
  staticDealer,
  setSelectedDealer,
  selectedDealer,
} from 'store/step3PageDataSlice';
import Spinner from '../../components/Spinner/Spinner';
import { setStep4Response } from 'store/step4PageDataSlice';
import Select from 'components/Select';
import { useForm } from 'react-hook-form';

const cx = classNames.bind(styles);

const Step3 = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      service: true,
    },
  });

  const dispatch = useDispatch();
  const pageData = useSelector(step3PageResponse);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedDealer, setIsSelectedDealer] = useState(true);
  const [dealersData, setDealersData] = useState([]);

  const urlParamsData = useSelector(urlParams);
  const dealerList = useSelector(dealerResponse);
  const dealerInfo = useSelector(staticDealer);
  const { dealer } = urlParamsData;

  const getStepPageData = () => {
    setIsLoading(true);
    getStepPage_3(urlParamsData)
      .then((res) => {
        dispatch(setStep3Response(res.data.data));
      })
      .catch((error) => {
        dispatch(setErrorData(error.response));
        dispatch(setActualStep('errorPage'));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    !Object.keys(pageData).length && getStepPageData();
    !dealer && getDealersData();
  }, []);

  const selectDealedFc = (dealerCode) => {
    dispatch(setSuccessfulStep_3(false));
    dispatch(setSuccessfulStep_4(false));
    dispatch(setSelectedDealer(dealerCode));
    const filteredItems = dealersData.filter(
      (item) => item.dealerCode.toLowerCase() === dealerCode.toLowerCase(),
    );

    dispatch(setStaticDealer(filteredItems[0]));
    setIsSelectedDealer(true);
  };

  const onSubmit = (data) => {
    console.log('sendData: ', data);
  };

  const getDealersData = () => {
    searchDealerRequest({
      lang: urlParamsData.lang,
      mark: urlParamsData.mark,
      distance: 2000,
      latitude: 59.804904,
      longitude: 30.320802,
    })
      .then((res) => {
        if (res) {
          setDealersData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (dealer && !dealerInfo) {
      setIsLoading(true);
      searchDealerRequest({
        lang: urlParamsData.lang,
        mark: urlParamsData.mark,
        distance: 2000,
        latitude: 59.804904,
        longitude: 30.320802,
      })
        .then((res) => {
          if (res) {
            setIsLoading(true);
            const filteredItems = res.data.data.filter(
              (item) =>
                item.dealerCode.toLowerCase() ===
                urlParamsData.dealer.toLowerCase(),
            );
            dispatch(setStaticDealer(filteredItems[0]));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          // setIsLoading(false);
          console.error(error);
        });
    }
  }, [!dealerInfo, dealer]);

  return (
    <div className={cx('step3')}>
      <Header title={pageData.title} stepNumber="3" />
      <>
        {!dealer ? (
          <div className={cx('selectDealer')}>
            {!isLoading ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Select
                  control={control}
                  options={pageData?.form?.dealers || []}
                  label="Оберіть дилерський центр"
                  name="dealer"
                  defaultValue={
                    dealerInfo
                      ? { label: dealerInfo.name, value: 'fsfds' }
                      : null
                  }
                  getOptionLabel={(opt) => (
                    <div className={'opt'}>
                      <span className="opt__label">{opt.label}</span>
                      <span className="opt__city">{opt.city}</span>
                    </div>
                  )}
                  getOptionValue={(opt) => opt.code}
                  onChange={(opt) => selectDealedFc(opt.code)}
                />
                {isSelectedDealer && dealerInfo ? (
                  <>
                    <div className={cx('dealer__title')}>
                      {dealerInfo?.name}
                    </div>
                    <div className={cx('dealer__list')}>
                      {dealerInfo &&
                        dealerInfo?.fields.map((el, index) => (
                          <div className={cx('dealer__row')} key={index}>
                            <div
                              className={cx('dealer__row-td', 'td-fildName')}
                            >
                              {el.label}
                            </div>
                            <div
                              className={cx('dealer__row-td', 'td-value')}
                              dangerouslySetInnerHTML={{
                                __html: el.value || '',
                              }}
                            />
                          </div>
                        ))}
                    </div>
                    <div className={cx('dealer__btn-wrap')}>
                      <Button
                        btnText={dealerInfo?.btnChooseDealerText}
                        onClick={() => {
                          // dispatch(setSelectedDealer(urlParamsData.dealer));
                          dispatch(setSuccessfulStep_3(true));
                          dispatch(setSuccessfulStep_4(false));
                          // dispatch(setStaticDealer(null));
                          dispatch(setStep4Response({}));
                          dispatch(setActualStep(4));
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ''
                )}
              </form>
            ) : (
              <Spinner />
            )}
          </div>
        ) : (
          <div className={cx('dealer')}>
            {!isLoading ? (
              <>
                <div className={cx('dealer__title')}>{dealerInfo?.name}</div>
                <div className={cx('dealer__list')}>
                  {dealerInfo &&
                    dealerInfo?.fields.map((el, index) => (
                      <div className={cx('dealer__row')} key={index}>
                        <div className={cx('dealer__row-td', 'td-fildName')}>
                          {el.label}
                        </div>
                        <div
                          className={cx('dealer__row-td', 'td-value')}
                          dangerouslySetInnerHTML={{
                            __html: el.value || '',
                          }}
                        />
                      </div>
                    ))}
                </div>
                <div className={cx('dealer__btn-wrap')}>
                  <Button
                    btnText={dealerInfo?.btnChooseDealerText}
                    onClick={() => {
                      dispatch(setSelectedDealer(urlParamsData.dealer));
                      dispatch(setSuccessfulStep_3(true));
                      dispatch(setSuccessfulStep_4(false));
                      // dispatch(setStaticDealer(null));
                      dispatch(setStep4Response({}));
                      dispatch(setActualStep(4));
                    }}
                  />
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default Step3;
