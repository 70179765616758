import React from 'react';
import classNames from 'classnames/bind';
import styles from './Tooltip.module.scss';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'assets/svg/TooltipIcon';

const cx = classNames.bind(styles);

const Tooltip = (props) => {
  const id = props.dataFor + (Math.random() * 100000000).toFixed().toString();

  return (
    <div className={cx('tooltip')}>
      <span
        data-tip
        data-for={id}
        data-event="click"
        className={cx('tooltip__icon')}
      >
        <TooltipIcon />
      </span>
      <ReactTooltip
        id={id}
        globalEventOff={props.dataEvent ?? 'click'}
        effect="solid"
        className={cx('tooltip__wrap')}
      >
        <div className={cx('tooltip__text')}>{props.tooltipText}</div>
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
