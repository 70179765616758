import { useDispatch } from 'react-redux';
import { setUrlParams } from 'store/urlParamsSlice';
var qs = require('qs');

export const useGetUrl = () => {
  const url = window.location.href;

  const dispatch = useDispatch();

  let params = {};

  const urlParams = qs.parse(url, { delimiter: /[?&]/ });

  if (urlParams && urlParams.dealer) {
    params = {
      ...params,
      dealer: urlParams.dealer,
    };
  }
  if (urlParams && urlParams.lang) {
    params = {
      ...params,
      lang: urlParams.lang,
    };
  }
  if (urlParams && urlParams.source) {
    params = {
      ...params,
      source: urlParams.source,
    };
  }
  if (urlParams && urlParams.mark) {
    params = {
      ...params,
      mark: urlParams.mark,
    };
  } else {
    params = {
      ...params,
      mark: 'lexus',
    };
  }

  return dispatch(setUrlParams(params));
};
