import * as React from 'react';

const ArrowRight = (props) => (
  <svg
    width={20}
    height={6}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 6"
    {...props}
  >
    <path
      d="M16.53.47a.75.75 0 1 0-1.06 1.06l.72.72H1a.75.75 0 0 0 0 1.5h15.19l-.72.72a.75.75 0 1 0 1.06 1.06L19.06 3 16.53.47Z"
      fill={props.color ? props.color : '#fff'}
    />
  </svg>
);

export default ArrowRight;
