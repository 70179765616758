import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import './customStyle.scss';

import styles from './datePicker.module.scss';
import { useController, useForm } from 'react-hook-form';
import DatePickerComponent from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import CalendarIcon from '../../assets/svg/CalendarIcon';

import { ru, enUS, uk, ro } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { urlParams } from 'store/urlParamsSlice';
import { addDays } from 'date-fns';

const cx = classNames.bind(styles);

const DatePicker = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState(false);
  const { register } = useForm();
  const required = props?.rules?.required;
  const firstNameRef = useRef(null);
  const { ref } = register(props.name);
  const { lang } = useSelector(urlParams);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const handleFocus = () => {
    if (field.value) {
      setFocus(true);
    } else {
      setFocus(false);
    }
  };

  const getLang = () => {
    let labels = {
      timeCaption: '',
      lang: enUS,
    };
    switch (lang) {
      case 'uk':
        return (labels = {
          timeCaption: 'Час',
          lang: uk,
        });
      case 'ro':
        return (labels = {
          timeCaption: 'Timp',
          lang: ro,
        });
      case 'en':
        return (labels = {
          timeCaption: 'Time',
          lang: enUS,
        });
      case 'ru':
        return (labels = {
          timeCaption: 'Время',
          lang: ru,
        });
      default:
        return (labels = {
          timeCaption: 'Time',
          lang: enUS,
        });
    }
  };

  useEffect(() => {
    if (field.value) {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <>
      <div
        className={
          props.errors ? cx('datePicker-wrap', 'error') : cx('datePicker-wrap')
        }
      >
        <div>
          <div className={focus ? cx('label', 'focus') : cx('label')}>
            {props.label}{' '}
            {required && !props.disabled ? (
              <span className={cx('required')}>*</span>
            ) : null}
          </div>
          <DatePickerComponent
            {...field}
            {...props}
            innerRef={field.ref}
            ref={(e) => {
              ref(e);
              firstNameRef.current = e;
            }}
            className={
              props.errors ? cx('datePicker', 'error') : cx('datePicker')
            }
            onBlur={(value) => handleFocus(value)}
            onCalendarOpen={() => setFocus(true)}
            onChange={(e) => {
              field.onChange(e);
              props?.onChange && props?.onChange();
            }}
            selected={field.value}
            disabled={props.disabled}
            dateFormat={props.dateFormat}
            timeFormat="HH:mm"
            timeCaption={getLang().timeCaption}
            locale={getLang().lang}
            showTimeSelect
            timeIntervals={60}
            // filterTime={filterPassedTime}
            minDate={addDays(new Date(), 1 + props.requestServiceIn)}
            includeTimes={[
              setHours(setMinutes(new Date(), 0), 9),
              setHours(setMinutes(new Date(), 0), 10),
              setHours(setMinutes(new Date(), 0), 11),
              setHours(setMinutes(new Date(), 0), 12),
              setHours(setMinutes(new Date(), 0), 13),
              setHours(setMinutes(new Date(), 0), 14),
              setHours(setMinutes(new Date(), 0), 15),
              setHours(setMinutes(new Date(), 0), 16),
              setHours(setMinutes(new Date(), 0), 17),
              setHours(setMinutes(new Date(), 0), 18),
            ]}
          />
          <span className={cx('icon')}>
            <CalendarIcon size={24} />
          </span>
        </div>
      </div>
      {props.errors && (
        <p className={cx('errorName')}>{props.errors.message}</p>
      )}
    </>
  );
};

export default DatePicker;
