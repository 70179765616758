import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames/bind';
import ReactSelect from 'react-select';

import styles from './style.module.scss';
const cx = classNames.bind(styles);

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    boxShadow: 'none',
    textAlign: 'left',
    background: 'transparent',
    // "&:hover": {
    //   boxShadow: "0 0 0 1px var(--gray-4)!important",
    // },
    '&:active': {
      boxShadow: '0 0 0 1px var(--input-border-color)!important',
    },
    '&:focus': {
      boxShadow: '0 0 0 1px var(--input-border-color)!important',
    },
    minHeight: '56px',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '16px',
    color: 'var(--primary-black)',
    textAlign: 'left',
    textTransform: 'initial',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '7px 20px',
    position: 'absolute',
    left: '0',
    top: '19px',
    width: '100%',
    zIndex: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    position: 'absolute',
    right: 0,
    top: '14px',
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: 'normal',
    fontSize: '16px',
    color: 'var(--primary-black)',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: '16px',
    backgroundColor: isSelected ? 'var(--gray-1)' : '#ffffff',
    color: isSelected ? 'var(--primary-black)' : 'var(--gray-7)',
    zIndex: 999999,
    padding: '16px 20px',
    textAlign: 'left',
    borderBottom: '1px solid var(--input-border-color)',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:active': {
      backgroundColor: '#fff',
    },
    '&:hover': {
      background: 'var(--gray-4)',
      cursor: 'pointer',
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 2,
  }),
  menuList: (styles) => ({
    ...styles,
    width: '100%',
    border: '1px solid var(--input-border-color)',
    textAlign: 'left',
    zIndex: '999',
    maxHeight: '300px',
    paddingTop: '0',
    paddingBottom: '0',
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: 'var(--white)',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--primary-red)',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: 'var(--primary-red)',
    },
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    marginRight: '23px',
    svg: {
      fill: 'var(--primary-black)',
      width: '15px',
      height: '15px',
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    fontSize: '16px',
    svg: {
      width: '25px',
      height: '25px',
    },
  }),
};

const Select = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState(false);
  // const selectRef = useRef(null);

  const required = props?.rules?.required;

  useEffect(() => {
    if (field.value) {
      setFocus(true);
    }
  }, [field.value]);

  return (
    <div className={props.disabled ? cx('disabled') : ''}>
      <div
        className={props.errors ? cx('selectWrap', 'error') : cx('selectWrap')}
      >
        <div className={focus ? cx('label', 'focus') : cx('label')}>
          {props.label}{' '}
          {required && !props.disabled ? (
            <span className={cx('required')}>*</span>
          ) : null}
        </div>
        <ReactSelect
          {...field}
          {...props}
          // ref={selectRef}
          styles={customStyles}
          options={props.options}
          isMulti={props.isMulti}
          classNamePrefix="select"
          className={focus ? cx('select', 'focus') : cx('input')}
          getOptionLabel={
            props.getOptionLabel ? props.getOptionLabel : (opt) => opt.value
          }
          getOptionValue={
            props.getOptionValue ? props.getOptionValue : (opt) => opt.key
          }
          disabled={props.disabled}
          onChange={(e) => {
            field.onChange(e);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
          placeholder=""
        />
      </div>
      {props.errors && (
        <p className={cx('errorName')}>{props.errors?.message}</p>
      )}
    </div>
  );
};

export default Select;
