import API from '../api';

export const getHeaderData = (params) => {
  let paramsString = new URLSearchParams(params);
  return API.get(`/api/v1/tabs?${paramsString}`);
};

export const getScriptData = () => {
  return API.get(`/api/v1/tags`);
};
